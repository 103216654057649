import axios from 'axios';

export function getProfile() {
    let dumbToken = JSON.parse(localStorage.getItem('userDetails'));
    const token = 'Bearer ' + dumbToken.data;
    const config = {
        headers: {
            'Authorization': token,
            'accept': '*/*'
        }
    };

    return axios.get(
        `https://core.pskeromais.com.br/api/v1/get-profile`,
        config
    );
}