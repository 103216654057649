import axios from 'axios';

export function RetrieveOrder(id) {
    console.log('productsList')
    let dumbToken = JSON.parse(localStorage.getItem('userDetails'));
    const token = 'Bearer ' + dumbToken.data
    const config = {
        headers:
        {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }

    const postData = {
        "OrderId": id
    }

    return axios.post(
        `https://core.pskeromais.com.br/api/v1/GetOrderRequest`,
        postData, config,
    );
}

export function ordersListMain( id ) {
    let dumbToken = JSON.parse(localStorage.getItem('userDetails'));
    const token = 'Bearer ' + dumbToken.data
    const config = {
        headers:
        {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }

    const postData = {
        "StoreId": dumbToken.profileId,
        "Page": 1,
        "PerPage": 20
    };

    return axios.post(
        `https://core.pskeromais.com.br/api/v1/GetOrdersByFilterRequest`,
        postData, config,
    );
}

export function orderConfirm( id ) {
    let dumbToken = JSON.parse(localStorage.getItem('userDetails'));
    const token = 'Bearer ' + dumbToken.data
    const config = {
        headers:
        {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }

    const postData = {
        "Id": id
    };

    return axios.post(
        `https://core.pskeromais.com.br/api/v1/ConfirmOrderCommand`,
        postData, config,
    );
}

export function orderRefuse( id ) {
    let dumbToken = JSON.parse(localStorage.getItem('userDetails'));
    const token = 'Bearer ' + dumbToken.data
    const config = {
        headers:
        {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }

    const postData = {
        "Id": id
    };

    return axios.post(
        `https://core.pskeromais.com.br/api/v1/RejectOrderCommand`,
        postData, config,
    );
}

export function orderDelivery( id ) {
    let dumbToken = JSON.parse(localStorage.getItem('userDetails'));
    const token = 'Bearer ' + dumbToken.data
    const config = {
        headers:
        {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }

    const postData = {
        "Id": id
    };

    return axios.post(
        `https://core.pskeromais.com.br/api/v1/ShippOrderCommand`,
        postData, config,
    );
}

export function orderDone( id ) {
    let dumbToken = JSON.parse(localStorage.getItem('userDetails'));
    const token = 'Bearer ' + dumbToken.data
    const config = {
        headers:
        {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }

    const postData = {
        "Id": id
    };

    return axios.post(
        `https://core.pskeromais.com.br/api/v1/DeliverOrderCommand`,
        postData, config,
    );
}