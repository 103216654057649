import React from "react";

const Footer = () => {
	var d = new Date();
	return (
		<div className="footer" style={{backgroundColor: 'rgb(136,159,255)' , backgroundImage: 'linear-gradient(32deg, rgba(136,159,255,1) 0%, rgba(18,176,81,1) 87%)' ,height: 23}}>
			<div className="copyright border-top">
				
			</div>
		</div>
	);
};

export default Footer;
